<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="主小程序" prop="appid">
        <el-input
          v-model="queryParams.wxappName"
          placeholder="请输入主小程序"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="跳转小程序id" prop="toAppid">
        <el-input
          v-model="queryParams.toAppid"
          placeholder="请输入跳转小程序id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="goWxappList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
          label="排序"
          type="index"
          width="50">
      </el-table-column>
<!--      <el-table-column label="主小程序id" align="center" prop="appid" />-->
      <el-table-column label="主小程序" align="center" prop="wxappName" />
      <el-table-column label="跳转小程序id" align="center" prop="toAppid" />
      <el-table-column label="跳转小程序" align="center" prop="toWxappName" />
<!--      <el-table-column label="跳转路径" align="center" prop="toPath" />-->
      <el-table-column label="展示封面">
        　　<template slot-scope="scope">
        　　　　<img :src="scope.row.toShowCover" width="60" height="40" />
        　　</template>
      </el-table-column>
      <el-table-column label="展示文案" align="center" prop="toShowTitle" />
<!--      <el-table-column label="展示封面" align="center" prop="toShowCover" />-->
      <el-table-column label="是否显示播放图标" align="center" prop="ifShowPlay" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.ifShowPlay"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="排序" align="center" prop="sort" />
      <el-table-column label="是否加激励视频" align="center" prop="ifVad" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.ifVad"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="是否跳转到引流二维码" align="center" prop="ifGoCode" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.ifGoCode"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="是否启用" align="center" prop="enable" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改小程序跳转管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="主小程序" prop="appid">
<!--          <el-input v-model="form.appid" placeholder="请输入主小程序id" />-->
          <el-select v-model="form.appid" placeholder="请选择小程序">

            <el-option  v-for="item in WxappList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.appid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转小程序id" prop="toAppid">
          <el-input v-model="form.toAppid" placeholder="请输入跳转小程序id" />
          <el-select v-model="form.toAppid" placeholder="请选择跳转小程序">

            <el-option  v-for="item in WxappList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.appid"
            >
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="跳转路径" prop="toPath">-->
<!--          <el-input v-model="form.toPath" placeholder="请输入跳转路径" />-->
<!--        </el-form-item>-->
        <el-form-item label="展示封面" prop="toShowCover">
          <el-input v-model="form.toShowCover" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.toShowCover" width="60" height="40" />
          </template>
          <el-upload
              class="upload-demo"
              :action="uploadImgUrl"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="1"

          >
            <el-button size="small" type="primary">点击上传</el-button>
<!--            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
<!--          <el-input v-model="form.toShowCover" placeholder="请输入展示封面" />-->
        </el-form-item>
        <el-form-item label="展示文案" prop="toShowTitle">
          <el-input v-model="form.toShowTitle" placeholder="请输入展示文案" />
        </el-form-item>
<!--        <el-form-item label="是否显示播放图标" prop="ifShowPlay">-->
<!--          <el-input v-model="form.ifShowPlay" placeholder="请输入是否显示播放图标" />-->
<!--        </el-form-item>-->
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入排序" />
        </el-form-item>
<!--        <el-form-item label="是否加激励视频" prop="ifVad">-->
<!--          <el-input v-model="form.ifVad" placeholder="请输入是否加激励视频" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="是否启用" prop="enable">-->
<!--          <el-input v-model="form.enable" placeholder="请输入是否启用" />-->
<!--        </el-form-item>-->

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listGoWxapp, getGoWxapp, delGoWxapp, addGoWxapp, updateGoWxapp, exportGoWxapp } from "@/api/work/goWxapp";
import { listWxapp} from "@/api/work/Wxapp";
export default {
  name: "GoWxapp",
  components: {
  },
  data() {
    return {
      uploadImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss", // 上传的图片服务器地址
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 小程序跳转管理表格数据
      goWxappList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 主键字典
      idOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        wxappName: null,
        toAppid: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      },
      //小程序列表
      WxappList:[]
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
  },
  methods: {
    //更新状态
    changeInformationStatus(value, { id }, { property }) {
      let parm=property

      let parms={
        id:id,
        [parm]:value
      }
      updateGoWxapp(
          parms
      )

    },
    //上传图片成功
    handleAvatarSuccess(res, file) {
      this.form.toShowCover=res.datas
      console.log(res)

    },
    /** 查询小程序跳转管理列表 */
    getList() {
      this.loading = true;
      listGoWxapp(this.queryParams).then(response => {
        this.goWxappList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 查询小程序管理列表 */
    getWxappList() {
      this.loading = true;
      listWxapp().then(response => {
        this.WxappList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        appid: null,
        toAppid: null,
        toPath: null,
        toShowCover: null,
        ifShowPlay: null,
        sort: null,
        ifVad: null,
        ifGoCode: null,
        enable: null,
        toShowTitle: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getWxappList();
      this.open = true;
      this.title = "添加小程序跳转管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getWxappList();

      const id = row.id || this.ids
      console.log(id)
      getGoWxapp(id).then(response => {
        this.form = response.datas;
        this.open = true;
        this.title = "修改小程序跳转管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateGoWxapp(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addGoWxapp(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除小程序跳转管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delGoWxapp(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有小程序跳转管理数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportGoWxapp(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    }
  }
};
</script>
