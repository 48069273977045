import request from '@/utils/request'

// 查询小程序管理列表
export function listWxapp(query) {
  return request({
    url: '/work/Wxapp/list',
    method: 'get',
    params: query
  })
}

// 查询小程序管理详细
export function getWxapp(id) {
  return request({
    url: '/work/Wxapp/' + id,
    method: 'get'
  })
}

// 新增小程序管理
export function addWxapp(data) {
  return request({
    url: '/work/Wxapp',
    method: 'post',
    data: data
  })
}

// 修改小程序管理
export function updateWxapp(data) {
  return request({
    url: '/work/Wxapp/update',
    method: 'post',
    data: data
  })
}

// 删除小程序管理
export function delWxapp(id) {
  return request({
    url: '/work/Wxapp/remove/' + id,
    method: 'get'
  })
}

// 获取小程序广告位列表
export function getListByAppid(data) {
  return request({
    url: '/work/appConfig/getListByAppid',
    method: 'post',
    data: data
  })
}
  // 修改小程序广告位
  export function updateAdConfig(data) {
    return request({
      url: '/work/appConfig/update',
      method: 'post',
      data: data
    })
}
// 获取小程序广告位详情
export function getAdConfig(id) {
  return request({
    url: '/work/appConfig/' + id,
    method: 'get'
  })
}