import request from '@/utils/request'

// 查询小程序跳转管理列表
export function listGoWxapp(query) {
  return request({
    url: '/work/goWxapp/list',
    method: 'get',
    params: query
  })
}

// 查询小程序跳转管理详细
export function getGoWxapp(id) {
  return request({
    url: '/work/goWxapp/' + id,
    method: 'get'
  })
}

// 新增小程序跳转管理
export function addGoWxapp(data) {
  return request({
    url: '/work/goWxapp',
    method: 'post',
    data: data
  })
}

// 修改小程序跳转管理
export function updateGoWxapp(data) {
  return request({
    url: '/work/goWxapp/update',
    method: 'post',
    data: data
  })
}

// 删除小程序跳转管理
export function delGoWxapp(id) {
  return request({
    url: '/work/goWxapp/remove/' + id,
    method: 'get'
  })
}

// 导出小程序跳转管理
export function exportGoWxapp(query) {
  return request({
    url: '/work/goWxapp/export',
    method: 'get',
    params: query
  })
}